<template>
  <b-card
    v-if="Object.keys(blogData).length"
    class="blog-add-wrapper"
  >
    <!-- media -->
    <b-media
      no-body
      vertical-align="center"
    >
      <b-media-aside class="mr-75">
        <b-avatar
          size="38"
          :src="blogData.avatar"
        />
      </b-media-aside>
      <b-media-body>
        <h6 class="mb-25">
          {{ blogData.user.fullName || blogData.user.username }}
        </h6>
        <b-card-text>{{ blogData.createdTime }}</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Title"
            label-for="blog-add-title"
            class="mb-2"
          >
            <b-form-input
              id="blog-add-title"
              v-model="blogData.blogTitle"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Slug"
            label-for="blog-add-slug"
            class="mb-2"
          >
            <b-form-input
              id="blog-add-slug"
              v-model="blogData.slug"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Content"
            label-for="blog-content"
            class="mb-2"
          >
            <quill-editor
              id="blog-content"
              ref="quillEditor"
              v-model="blogData.excerpt"
              placeholder="Nhập nội dung tại đây"
              :options="editorOption"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="mb-2"
        >
          <div class="border rounded p-2">
            <h4 class="mb-1">
              Update image upload/download
            </h4>
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <b-media-aside>
                <b-img
                  ref="refPreviewEl"
                  :src="blogData.avatar_url"
                  height="110"
                  width="170"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
              </b-media-aside>
              <b-media-body>
                <small class="text-muted">Required image resolution 800x400, image size 10mb.</small>
                <b-card-text class="my-50">
                  <b-link id="blog-image-text">
                    C:\fakepath\{{ blogFile ? blogFile.name : 'banner.jpg' }}
                  </b-link>
                </b-card-text>
                <div class="d-inline-block">
                  <b-form-file
                    ref="refInputEl"
                    v-model="blogFile"
                    accept=".jpg, .png, .gif"
                    placeholder="Choose file"
                    @input="inputImageRenderer"
                  />
                </div>
              </b-media-body>
            </b-media>
          </div>
        </b-col>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
          >
            Lưu thông tin
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
          >
            Hủy
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import ImageResize from 'quill-image-resize-module'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      blogData: {
        user: JSON.parse(localStorage.getItem('userData')),
        createdTime: 'Today',
        title: '',
        slug: '',
        content:
            '<p>Cupcake ipsum dolor sit. Amet dessert donut candy chocolate bar cotton dessert candy chocolate. Candy muffin danish. Macaroon brownie jelly beans marzipan cheesecake oat cake. Carrot cake macaroon chocolate cake. Jelly brownie jelly. Marzipan pie sweet roll.</p><p><br></p><p>Liquorice dragée cake chupa chups pie cotton candy jujubes bear claw sesame snaps. Fruitcake chupa chups chocolate bonbon lemon drops croissant caramels lemon drops. Candy jelly cake marshmallow jelly beans dragée macaroon. Gummies sugar plum fruitcake. Candy canes candy cupcake caramels cotton candy jujubes fruitcake.</p>',
        avatar_url: require('@/assets/images/slider/03.jpg'),
      },
      blogFile: null,
      categoryOption: ['Fashion', 'Food', 'Gaming', 'Quote', 'Video'],
      statusOption: ['Published', 'Pending', 'Draft'],
      editorOption: {
        theme: 'snow',
        // modules: {
        //   // toolbar: {
        //   //   container: [
        //   //     [{ header: [1, 2, 3, 4, 5, 6, false] }],
        //   //     ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        //   //     ['blockquote'],
        //   //     [{ list: 'ordered' }, { list: 'bullet' }],
        //   //     [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        //   //     [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        //   //     [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        //   //     [{ align: [] }],
        //   //     ['image', 'video'],
        //   //     ['clean'], // remove formatting button
        //   //   ],
        //   //   handlers: {
        //   //     image: () => {
        //   //       const input = document.createElement('input')
        //   //
        //   //       input.setAttribute('type', 'file')
        //   //       input.setAttribute('accept', 'image/*')
        //   //       input.click()
        //   //
        //   //       input.onchange = async () => {
        //   //         const editor = this.$refs.quillEditor.quill
        //   //         const file = input.files[0]
        //   //         const formData = new FormData()
        //   //
        //   //         formData.append('image', file)
        //   //
        //   //         // Save current cursor state
        //   //         const range = editor.getSelection(true)
        //   //
        //   //         // Insert temporary loading placeholder image
        //   //         editor.insertEmbed(range.index, 'image', 'https://hub.devculi.com/loading.png')
        //   //
        //   //         // Move cursor to right side of image (easier to continue typing)
        //   //         editor.setSelection(range.index + 1)
        //   //
        //   //         // Post to an api endpoint which uploads to s3. It returns the s3 url
        //   //         const res = await this.$http.post('/upload/image', formData)
        //   //
        //   //         console.log(res.data)
        //   //         // Remove placeholder image
        //   //         editor.deleteText(range.index, 1)
        //   //
        //   //         // Insert uploaded image
        //   //         console.log(process.env.APP_BASE_URL)
        //   //         console.log('upload response: ', res.data)
        //   //         editor.insertEmbed(range.index, 'image', `process.env.APP_BASE_URL/${res.data}`)
        //   //       }
        //   //       // https://forum.vuejs.org/t/how-to-upload-image-in-s3-by-using-quileditor/49452
        //   //       // https://jsfiddle.net/jamesbrndwgn/23tnmeu5/21/
        //   //     },
        //   //   },
        //   // },
        //   imageResize: {},
        // },
      },
    }
  },
  created() {},
  methods: {
    // eslint-disable-next-line no-unused-vars
    onEditorBlur(quill) {
    },
    // eslint-disable-next-line no-unused-vars
    onEditorFocus(quill) {
    },
    // eslint-disable-next-line no-unused-vars
    onEditorReady(quill) {
      window.Quill.register('modules/imageResize', ImageResize)
    },
  },
  setup() {
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { refPreviewEl.value.src = base64 })

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
